import { useState } from 'react';
import { Modal, Spin, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import galleryService from '../services/gallery';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const ImageGallery = ({
  fileList,
  setFileList,
  type,
  form,
  disabled = false,
  setIsModalOpen,
}) => {
  const { t } = useTranslation();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
  };

  const createImage = (file) => {
    return {
      uid: file.title,
      name: file.title,
      status: 'done', // done, uploading, error
      url: file.title,
      created: true,
    };
  };

  const uploadButton = loading ? (
    <div>
      <Spin />
    </div>
  ) : (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('upload')}
      </div>
    </div>
  );

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const resizeAndUploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const targetWidth = 1080;
          const targetHeight = 1920;

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = targetWidth;
          canvas.height = targetHeight;

          const scale = Math.max(
            targetWidth / img.width,
            targetHeight / img.height,
          );
          const x = (targetWidth - img.width * scale) / 2;
          const y = (targetHeight - img.height * scale) / 2;

          ctx.fillStyle = 'black';
          ctx.fillRect(0, 0, targetWidth, targetHeight);
          ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

          const resizedImageURL = canvas.toDataURL('image/jpeg', 0.85);
          const resizedFile = dataURLtoFile(resizedImageURL, file.name);

          resolve({
            originalFile: file,
            resizedFile: resizedFile,
            previewUrl: resizedImageURL,
          });
        };

        img.onerror = () => reject(new Error('Failed to load image'));
      };

      reader.onerror = () => reject(new Error('Failed to read file'));

      reader.readAsDataURL(file);
    });
  };

  const handleUpload = async ({ file, onSuccess }) => {
    setLoading(true);

    if (type === 'shops/kiosk') {
      try {
        const resized = await resizeAndUploadImage(file);
        file = resized.resizedFile;
      } catch (error) {
        toast.error('Image processing failed');
        setLoading(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);
    galleryService
      .upload(formData)
      .then(({ data }) => {
        setFileList((prev) => [...prev, createImage(data)]);
        form.setFieldsValue({
          images: [...fileList, createImage(data)],
        });
        onSuccess('ok');
      })
      .finally(() => {
        setIsModalOpen(false);
        setLoading(false);
      });
  };

  const handleRemove = (file) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
  };

  return (
    <>
      <Upload
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        customRequest={handleUpload}
        className={disabled ? 'antdImgUpload' : 'antdImgUpload'}
        onRemove={handleRemove}
        showUploadList={false}
        accept='.png,.jpg,.jpeg,.webp'
        beforeUpload={(file) => {
          const isItAtLeast2MB = file.size / 1024 / 1024 < 2;
          if (!isItAtLeast2MB) {
            toast.error(t('max.2.mb'));
            return false;
          }
          return true;
        }}
      >
        {fileList.length >= 24 || disabled ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt='example'
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default ImageGallery;
