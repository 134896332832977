// ** React Imports
import { lazy } from 'react';

const SellerKioskRoutes = [
  {
    path: 'seller/kiosk/screen',
    component: lazy(() => import('views/seller-views/kiosk-screen')),
  },
];

export default SellerKioskRoutes;
